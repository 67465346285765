<template>
  <v-card
    :loading="loading"
    :to="`/assets/collections/${id}`"
    class="mx-auto fill-height d-flex flex-column flex-wrap w-100"
    width="100%"
    rounded="xl"
    transition="fade-transition"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      :src="imageUrl"
      :height="imageHeight"
      max-width="100%"
      cover
    />

    <v-card-text class="mt-auto">
      <section class="d-flex align-center">
        <v-avatar size="40" class="mb-5">
          <v-img
            height="250"
            :src="imageUrl"
          ></v-img>
        </v-avatar>

        <div class="fill-width overflow-hidden">
          <v-card-title class="pt-0 d-block text-truncate pr-0">#{{ id }} {{ name }}</v-card-title>
          <v-card-subtitle class="pb-0">{{ slug }}</v-card-subtitle>
        </div>
      </section>
    </v-card-text>

<!--    <template v-if="showFavourites">-->
<!--      <v-divider />-->
<!--      <v-card-actions class="justify-end">-->
<!--        <v-btn icon color="red">-->
<!--          <v-icon>mdi-heart</v-icon>-->
<!--        </v-btn>-->
<!--      </v-card-actions>-->
<!--    </template>-->
  </v-card>
</template>

<script>
export default {
  name: 'CollectionCard',
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: () => (false),
    },
    name: {
      type: String,
      required: true,
      default: () => (''),
    },
    slug: {
      type: String,
      required: true,
      default: () => (''),
    },
    isVerified: {
      type: Boolean,
      required: false,
      default: () => (false),
    },
    imageUrl: {
      type: String,
      required: true,
      default: () => (''),
    },
    imageHeight: {
      type: [String, Number],
      required: false,
      default: () => (500),
    },
    __typename: {
      type: String,
      required: false,
      default: () => (500),
    },
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => { this.loading = false; }, 2000);
    },
  },
};
</script>

```
<usage>
<collection-card :
</usage>
